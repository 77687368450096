.mpng-ux-tabs.nav-tabs {
  border-color: var(--gray-light);

  .nav-item {
    background-color: transparent;
    color: var(--dark);
    border-width: 0 0 1px 0;
    font-weight: bold;
    font-size: 0.8em;
    border-radius: 0;

    padding-left: 5em;
    padding-right: 5em;

    &:hover {
      border-color: var(--gray);
    }

    &.active {
      background-color: transparent;
      border-color: var(--gray);
      border-width: 0 0 2px 0;
    }
  }

  .mpng-ux-tab {
    margin-top: 1.5rem;
  }
}
