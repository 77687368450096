@use "sass:color";

.mpng-ux-table {
  .row {
    vertical-align: top;
  }

  .table-heading {
    vertical-align: bottom;
  }

  // seperating borders
  &.table-separated {
    .row {
      border-top: 1px solid var(--gray-light);
    }

    .table-heading,
    .table-row {
      border-top: none;
    }

    .table-heading {
      border-bottom: 1px solid var(--gray-light);
    }
  }

  // row striping
  &.table-striped {
    .table-row:nth-of-type(even) {
      background-color: var(--light);
    }
  }

  // row hover
  &.table-hover .table-row {
    &:hover,
    :focus {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.focus-class .table-row-toggle,
    &.focus-class .mpng-ux-icon.mr-3 {
      background-color: var(--gray-dark);
      color: var(--white);
    }
  }
}
