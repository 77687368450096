.mpng-ux-num-students {
  padding-left: 2em;
  font-weight: bold;

  .mpng-ux-students-icon {
    padding-right: 8px;
    color: #454545;
  }

  .mpng-ux-student-count {
    font-size: 12px;
  }
}
