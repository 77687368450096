.mpng-ux-expanding-table {
  .table-heading,
  .table-row {
    margin-left: 0;
    margin-right: 0;
  }

  .table-heading-container {
    display: flex;
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .table-heading {
    width: 100%;
  }

  .table-row-cap {
    height: 100%;
  }

  .table-row-toggle {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table-row-toggle,
  .table-row-cols {
    display: flex;
    width: 100%;
    max-width: 100%;
  }

  .collapse-container {
    width: 100%;
  }

  // seperating borders
  &.table-separated {
    .table-row .table-row-toggle {
      border-top: 1px solid var(--gray-light);
    }
    .table-row:last-child .table-row-toggle {
      border-bottom: 1px solid var(--gray-light);
    }
  }

  // row striping
  &.table-striped {
    .table-row:nth-of-type(odd) .table-row-toggle {
      background-color: var(--light);
    }
  }

  // row hover
  &.table-hover .table-row-toggle {
    &:hover,
    &:focus {
      color: var(--dark);
      background-color: var(--light);
    }
  }

  // focused row
  .table-row-toggle.focused {
    color: var(--white);
    background-color: var(--dark);
  }
}
