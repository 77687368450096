.mpng-ux-progress.progress {
  background-color: unset;
  display: flex;
  align-items: center;

  .bar {
    height: 100%;
  }

  .bar-bg {
    height: 100%;
    background-color: var(--light);

    .progress-label {
      color: var(--dark);
    }
  }
}
