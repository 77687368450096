.navbar-brand text {
  color: var(--primary);
  font-size: xx-small;
}

.mpng-ux-navbar {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &.sidebar {
    right: inherit;
    bottom: 0;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  // Adjust layout of mobile header of lessons page
  .nav-bar-mobile.navbar-collapse {
    .navbar-bg {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .nav-bar-mobile.navbar-collapse {
      flex: 0 0 auto;
      width: 100%;
      position: absolute;
      margin: 0px;
      top: 60px;
      left: 0px;

      .navbar-bg {
        display: block;
      }

      .justify-content-end {
        align-items: flex-start !important;
        flex-direction: inherit;

        .dropdown {
          .dropdown-menu {
            min-width: 100px;
            text-align: center;
            position: absolute;
          }
        }
      }
    }
  }
}
