.mpng-ux-timeline {
  $border: 4px solid var(--dark);
  $marginLeft: 1.3em;
  $borderMove: 3em;
  $height: 5em;
  $bubbleSize: 2em;

  .mpng-ux-accordion-child {
    .timeline-title:before,
    .timeline-child:before {
      content: "";
      height: $height;
      position: absolute;
      border-left: $border;
      margin-left: $marginLeft;
      z-index: 1;
      margin-top: -1em;
    }

    .timeline-title {
      .timeline-marker {
        &.complete {
          background-color: var(--gray);
          border-color: var(--gray);
          color: var(--white);
        }
        &.interactive {
          background-color: var(--gray-dark);
          border-color: var(--gray-dark);
          color: var(--white);
        }
        &.locked {
          color: var(--gray-light);
          background-color: var(--light);
        }

        border-style: solid;
        border-width: 4px;
      }

      .timeline-open-indicator {
        width: 15px;
        display: contents;
      }

      .rounded-circle {
        width: $bubbleSize;
        height: $bubbleSize;
      }

      &.locked {
        &:before {
          border-color: var(--gray-light);
        }
      }
    }
    .timeline-child {
      margin-left: -1em;

      &.focus-class {
        background-color: var(--gray-light);
      }

      &.locked {
        &:before {
          border-color: var(--gray-light);
        }
      }

      &:before {
        margin-left: 0;
        margin-top: -2em;
      }

      .timeline-marker {
        border-width: 2px;
        border-style: solid;
        margin-left: -0.5em;
      }

      .rounded-circle {
        width: $bubbleSize * 0.6;
        height: $bubbleSize * 0.6;
      }

      .timeline-marker {
        border-color: var(--dark);
        background-color: var(--light);
        &.locked {
          border-color: var(--gray-light);
        }
        &.interactive {
          background-color: var(--dark);
        }
      }
    }

    &:first-child {
      .timeline-title:before {
        margin-top: $borderMove;
      }
    }

    &:last-child {
      .timeline-title:not(.selected):before {
        margin-top: -1 * $borderMove;
      }
      .timeline-child:last-child:before {
        margin-top: -1 * ($borderMove + 2);
      }
    }

    &:only-child {
      .timeline-title:last-child::before {
        height: 0;
      }
    }

    .timeline-body {
      margin-left: $marginLeft;
    }
  }
}
