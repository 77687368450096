.column-stagger > div:nth-child(even) {
  align-self: flex-end;
}

.back-to-current {
  z-index: 1030;
  float: right;
  position: fixed;
  right: 25px;
  bottom: 10px;
  animation: fadeIn 1s;
}

.back-to-current:hover {
  box-shadow: 0 0 0 2px #ffffff, inset 0 0 0 3px #1865f3, inset 0 0 0 5px white;
  border-radius: 8px !important;
}

.back-to-current:focus {
  box-shadow: 0 0 0 2px #ffffff, inset 0 0 0 3px #1865f3, inset 0 0 0 5px white;
  border-radius: 8px !important;
}

.back-to-current:focus-within {
  box-shadow: 0 0 0 2px #ffffff, inset 0 0 0 3px #1865f3, inset 0 0 0 5px white;
  border-radius: 8px !important;
}

.back-to-current-label .mpng-ux-enhanced-icon .enhanced-icon-text {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 3px 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}
