.mpng-ux-modal {
  .modal-dialog.hide-container {
    .modal-content {
      background: transparent;
    }
  }

  .modal-dialog.hide-borders {
    .modal-content {
      border: 0 none;
    }
  }

  .modal-dialog.h-100 {
    .modal-content {
      height: 100%;
      border: 0 none;
      border-radius: 0;
      max-height: 100%;
    }
  }
}
