div.mpng-ux-visual-action {
  border: none;

  &.loading,
  &.error {
    .visual-action-body {
      opacity: 0;
      z-index: -1;
    }
  }

  &.loading:after {
    background-color: var(--dark);
  }

  &.error {
    overflow: hidden;
    border-color: var(--danger);

    &:after {
      content: "Error";
      color: var(--danger);
      font-size: 1.5em;
      top: calc(50% - 0.75em);
      text-align: center;
    }
  }
}
