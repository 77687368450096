/* index.scss */
@import "../../styles/variables.scss";

/**
* theme variables
*/

/**
* bootstrap specific
*/
// ---colors
$blue: #1f2163 !default;
// $indigo:     #6610f2 !default;
// $purple:     #6f42c1 !default;
// $pink:       #e83e8c !default;
// $red:        #dc3545 !default;
// $orange:     #fd7e14 !default;
// $yellow:     #ffc107 !default;
// $green:      #28a745 !default;
$white-smoke: #f5f5f5 !default;
$green: #37a447 !default;
$teal: #2e7f9e !default;
// $cyan:       #17a2b8 !default
$cyan: #329485 !default;

// ---bootstrap
// $primary:       $blue !default;
$secondary: #6d65ae !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// import theme modifiers
@import "modifiers";

// stays at botttom
@import "../../styles/base.scss";
